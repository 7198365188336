.back_drop {
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 999;
    background-color: rgb(0 0 0 / 40%);
}

.login_modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 25px 1rem 30px;
    z-index: 1000;
    background-color: #ffffff;
    border: 1px solid #707070;
    border-radius: 1rem;
    width: 600px;
    max-width: 90%;

    .close_btn {
        position: absolute;
        left: 16px;
        top: 10px;
        cursor: pointer;

        svg {
            width: 16px;
            height: 16px;
        }
    }

    .profile_icon {
        position: absolute;
        left: 50%;
        top: -30px;
        transform: translateX(-50%);

        svg {
            width: 60px;
            height: 60px;
        }
    }

    .modal_body {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 1.5rem;

        .title {
            font-family: "Tajawal", Helvetica, sans-serif;
            font-size: 16px;
            font-weight: 500;
            color: #011723;
            margin-bottom: 0;
        }

        .content {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 1rem;

            .title {
                font-family: "Tajawal", Helvetica, sans-serif;
                font-size: 16px;
                font-weight: 500;
                color: #011723;
            }

            .options {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                flex-direction: row;
                justify-content: center;
                gap: 1rem;
                margin-bottom: 4rem;
                margin-top: 1.5rem;

                button {
                    width: 200px;
                    max-width: 90%;
                    height: 80px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    gap: 1rem;
                    padding: 1rem;
                    border-radius: 4px;
                    border: 1px solid var(--primary-bg);
                    background-color: transparent;
                    outline: none;

                    p {
                        margin: 0;
                        color: var(--primary-bg);
                        font-family: "Tajawal", Helvetica, sans-serif;
                        font-size: 14px;
                        font-weight: 600;
                        letter-spacing: 0.2px;
                    }

                    svg {
                        width: 30px;
                        height: 30px;
                    }
                }
            }
        }

        .phone_content {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 1rem;

            .phone_input {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 10px;

                label {
                    font-family: "Tajawal", Helvetica, sans-serif;
                    font-size: 16px;
                    font-weight: 500;
                    color: #011723;
                }

                .input_content {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 10px;
                    width: 100%;
                    height: 60px;
                    background-color: #1dbbbe0f;
                    padding: 20px;

                    input {
                        width: 100%;
                        height: 100%;
                        background-color: transparent;
                        color: #011723;
                        border-radius: 4px;
                        outline: none;
                        border: none;
                        direction: ltr;

                        ::placeholder {
                            color: #c6c6c6;
                        }
                    }

                    .phone_code {
                        display: flex;
                        flex-direction: row;
                        margin: 0;
                        min-width: 0;

                        .MuiInputBase-root,
                        .MuiSelect-root {
                            .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input {
                                display: flex;
                                flex-direction: row;
                                align-items: center;
                                gap: 8px;
                                padding: 0;

                                #saudi {
                                    width: 16px;
                                    height: 16px;
                                }

                                p {
                                    font-family: "Tajawal", Helvetica,
                                        sans-serif;
                                    font-size: 16px;
                                    font-weight: 500;
                                    color: #011723;
                                    margin: 0;
                                    direction: ltr;
                                }
                            }

                            .MuiSelect-icon {
                                position: initial;
                                width: 25px;
                                height: 25px;
                            }

                            .MuiOutlinedInput-notchedOutline {
                                border: none;
                                padding: 0;
                            }
                        }
                    }
                }

                .error {
                    font-family: "Tajawal", Helvetica, sans-serif;
                    font-size: 13px;
                    font-weight: 500;
                    color: #f00c0c;
                    margin-bottom: 0;
                    margin-top: 0;
                }
            }

            .confirm_input {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 10px;
                margin-top: 20px;

                label {
                    font-family: "Tajawal", Helvetica, sans-serif;
                    font-size: 16px;
                    font-weight: 500;
                    color: #011723;
                }

                input {
                    width: 100%;
                    height: 60px;
                    background-color: #1dbbbe0f;
                    color: #011723;
                    border-radius: 4px;
                    padding: 20px;
                    outline: none;
                    border: none;

                    ::placeholder {
                        color: #c6c6c6;
                    }
                }
            }

            .send {
                width: 100%;
                height: 60px;
                background-color: var(--primary-bg);
                color: #f7fcff;
                font-family: "Tajawal", Helvetica, sans-serif;
                font-size: 16px;
                font-weight: 400;
                border-radius: 4px;
                margin-top: 40px;
                border: none;
                outline: none;
            }

            .error {
                align-self: flex-start;
                font-family: "Tajawal", Helvetica, sans-serif;
                font-size: 13px;
                font-weight: 500;
                color: #f00c0c;
                margin-bottom: 0;
                margin-top: 10px;
            }

            .confirm {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                gap: 10px;
                width: 50%;
                height: 60px;
                background-color: #c6c6c6;
                color: #f7fcff;
                font-family: "Tajawal", Helvetica, sans-serif;
                font-size: 16px;
                font-weight: 400;
                border-radius: 4px;
                margin-top: 40px;
                border: none;
                outline: none;

                &.loading_finish {
                    background-color: #0eee85;
                }
                &.active{
                    background-color: #0eee85;
                }

                svg {
                    width: 18px;
                    height: 18px;
                }
                &:disabled{
                    background-color: #c6c6c6;
                    cursor: not-allowed;
                }
            }

            .retry_send {
                font-family: "Tajawal", Helvetica, sans-serif;
                font-size: 16px;
                font-weight: 500;
                color: #011723;
                margin-top: 30px;
                margin-bottom: 0;
                outline: none;
                border: none;
                background-color: transparent;

                &:disabled {
                    color: #707070;
                    cursor: not-allowed;
                }
            }

            .options {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 1rem;
                margin-top: 20px;
                flex-wrap: wrap;
                justify-content: center;

                button {
                    width: 200px;
                    height: 55px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    gap: 1rem;
                    padding: 1rem;
                    border-radius: 4px;
                    border: 1px solid #1dbbbe33;
                    background-color: transparent;
                    outline: none;

                    &.disabled {
                        border: 1px solid #dcdcdc;
                    }

                    p {
                        margin: 0;
                        color: var(--primary-bg);
                        font-family: "Tajawal", Helvetica, sans-serif;
                        font-size: 14px;
                        font-weight: 600;
                        letter-spacing: 0.2px;

                        &.disabled {
                            color: #dcdcdc;
                        }
                    }

                    svg {
                        width: 30px;
                        height: 30px;
                    }
                }
            }
        }

        .email_content {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 1rem;

            .email_input {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 10px;

                label {
                    font-family: "Tajawal", Helvetica, sans-serif;
                    font-size: 16px;
                    font-weight: 500;
                    color: #011723;
                }

                .input_content {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: flex-end;
                    gap: 1rem;
                    width: 100%;
                    height: 60px;
                    background-color: #1dbbbe0f;
                    border-radius: 4px;
                    padding: 20px;

                    input {
                        width: 100%;
                        height: 100%;
                        background-color: transparent;
                        color: #011723;
                        outline: none;
                        border: none;
                        direction: ltr;

                        ::placeholder {
                            color: #c6c6c6;
                        }
                    }

                    svg {
                        width: 20px;
                        height: 20px;
                    }
                }

                .error {
                    font-family: "Tajawal", Helvetica, sans-serif;
                    font-size: 13px;
                    font-weight: 500;
                    color: #f00c0c;
                    margin-bottom: 0;
                    margin-top: 0;
                }
            }

            .confirm_input {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 10px;
                margin-top: 20px;

                label {
                    font-family: "Tajawal", Helvetica, sans-serif;
                    font-size: 16px;
                    font-weight: 500;
                    color: #011723;
                }

                input {
                    width: 100%;
                    height: 60px;
                    background-color: #1dbbbe0f;
                    color: #011723;
                    border-radius: 4px;
                    padding: 20px;
                    outline: none;
                    border: none;

                    ::placeholder {
                        color: #c6c6c6;
                    }
                }
            }

            .send {
                width: 100%;
                height: 60px;
                background-color: var(--primary-bg);
                color: #f7fcff;
                font-family: "Tajawal", Helvetica, sans-serif;
                font-size: 16px;
                font-weight: 400;
                border-radius: 4px;
                margin-top: 40px;
                border: none;
                outline: none;
            }

            .error {
                align-self: flex-start;
                font-family: "Tajawal", Helvetica, sans-serif;
                font-size: 13px;
                font-weight: 500;
                color: #f00c0c;
                margin-bottom: 0;
                margin-top: 10px;
            }

            .confirm {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                gap: 10px;
                width: 50%;
                height: 60px;
                background-color: #c6c6c6;
                color: #f7fcff;
                font-family: "Tajawal", Helvetica, sans-serif;
                font-size: 16px;
                font-weight: 400;
                border-radius: 4px;
                margin-top: 40px;
                border: none;
                outline: none;

                &.loading_finish {
                    background-color: #0eee85;
                }
                &.active{
                    background-color: #0eee85;
                }

                svg {
                    width: 18px;
                    height: 18px;
                }
                &:disabled{
                    background-color: #c6c6c6;
                    cursor: not-allowed;
                }
            }

            .retry_send {
                font-family: "Tajawal", Helvetica, sans-serif;
                font-size: 16px;
                font-weight: 500;
                color: #011723;
                margin-top: 30px;
                margin-bottom: 0;
                outline: none;
                border: none;
                background-color: transparent;

                &:disabled {
                    color: #707070;
                    cursor: not-allowed;
                }
            }

            .options {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 1rem;
                margin-top: 20px;
                flex-wrap: wrap;
                justify-content: center;

                button {
                    width: 200px;
                    height: 55px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    gap: 1rem;
                    padding: 1rem;
                    border-radius: 4px;
                    border: 1px solid #1dbbbe33;
                    background-color: transparent;
                    outline: none;

                    &.disabled {
                        border: 1px solid #dcdcdc;
                    }

                    p {
                        margin: 0;
                        color: var(--primary-bg);
                        font-family: "Tajawal", Helvetica, sans-serif;
                        font-size: 14px;
                        font-weight: 600;
                        letter-spacing: 0.2px;

                        &.disabled {
                            color: #dcdcdc;
                        }
                    }

                    svg {
                        width: 30px;
                        height: 30px;
                    }
                }
            }
        }
    }

    .register_body {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 1.5rem;

        .title {
            font-family: "Tajawal", Helvetica, sans-serif;
            font-size: 16px;
            font-weight: 500;
            color: #011723;
            margin-bottom: 0;
        }

        .name_inputs {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 1rem;
            margin-top: 40px;

            .name_input {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                label {
                    font-family: "Tajawal", Helvetica, sans-serif;
                    font-size: 16px;
                    font-weight: 500;
                    color: #011723;
                }

                .input_content {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: flex-end;
                    gap: 10px;
                    width: 100%;
                    height: 60px;
                    background-color: #1dbbbe0f;
                    border-radius: 4px;
                    padding: 20px;

                    input {
                        width: 100%;
                        height: 100%;
                        background-color: transparent;
                        color: #011723;
                        outline: none;
                        border: none;

                        ::placeholder {
                            color: #c6c6c6;
                        }
                    }

                    svg {
                        width: 20px;
                        height: 20px;
                    }
                }

                .error {
                    font-family: "Tajawal", Helvetica, sans-serif;
                    font-size: 13px;
                    font-weight: 500;
                    color: #f00c0c;
                    margin-bottom: 0;
                    margin-top: 0;
                }
            }
        }

        .phone_content {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 1rem;

            .phone_input {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 10px;

                label {
                    font-family: "Tajawal", Helvetica, sans-serif;
                    font-size: 16px;
                    font-weight: 500;
                    color: #011723;
                }

                .input_content {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 10px;
                    width: 100%;
                    height: 60px;
                    background-color: #1dbbbe0f;
                    padding: 20px;

                    input {
                        width: 100%;
                        height: 100%;
                        background-color: transparent;
                        color: #011723;
                        border-radius: 4px;
                        outline: none;
                        border: none;
                        direction: ltr;

                        ::placeholder {
                            color: #c6c6c6;
                        }
                    }

                    .phone_code {
                        display: flex;
                        flex-direction: row;
                        margin: 0;
                        min-width: 0;

                        .MuiInputBase-root,
                        .MuiSelect-root {
                            .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input {
                                display: flex;
                                flex-direction: row;
                                align-items: center;
                                gap: 8px;
                                padding: 0;

                                #saudi {
                                    width: 16px;
                                    height: 16px;
                                }

                                p {
                                    font-family: "Tajawal", Helvetica,
                                        sans-serif;
                                    font-size: 16px;
                                    font-weight: 500;
                                    color: #011723;
                                    margin: 0;
                                    direction: ltr;
                                }
                            }

                            .MuiSelect-icon {
                                position: initial;
                                width: 25px;
                                height: 25px;
                            }

                            .MuiOutlinedInput-notchedOutline {
                                border: none;
                                padding: 0;
                            }
                        }
                    }
                }

                .error {
                    font-family: "Tajawal", Helvetica, sans-serif;
                    font-size: 13px;
                    font-weight: 500;
                    color: #f00c0c;
                    margin-bottom: 0;
                    margin-top: 0;
                }
            }
        }

        .email_content {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 1rem;

            .email_input {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 10px;

                label {
                    font-family: "Tajawal", Helvetica, sans-serif;
                    font-size: 16px;
                    font-weight: 500;
                    color: #011723;
                }

                .input_content {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: flex-end;
                    gap: 1rem;
                    width: 100%;
                    height: 60px;
                    background-color: #1dbbbe0f;
                    border-radius: 4px;
                    padding: 20px;

                    input {
                        width: 100%;
                        height: 100%;
                        background-color: transparent;
                        color: #011723;
                        outline: none;
                        border: none;
                        direction: ltr;

                        ::placeholder {
                            color: #c6c6c6;
                        }
                    }

                    svg {
                        width: 20px;
                        height: 20px;
                    }
                }

                .error {
                    font-family: "Tajawal", Helvetica, sans-serif;
                    font-size: 13px;
                    font-weight: 500;
                    color: #f00c0c;
                    margin-bottom: 0;
                    margin-top: 0;
                }
            }
        }

        .register {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            gap: 10px;
            width: 40%;
            height: 60px;
            background-color: var(--primary-bg);
            color: #f7fcff;
            font-family: "Tajawal", Helvetica, sans-serif;
            font-size: 16px;
            font-weight: 400;
            border-radius: 4px;
            margin-top: 40px;
            border: none;
            outline: none;
        }
    }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

.MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters.Mui-selected {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    background-color: #1dbbbe0f;

    p {
        font-family: "Tajawal", Helvetica, sans-serif;
        font-size: 16px;
        font-weight: 500;
        color: #011723;
        margin: 0;
        direction: ltr;
    }

    #saudi {
        width: 16px;
        height: 16px;
    }
}

@media (max-width: breakpoint(sm-end)) {
    .login_modal {
        border-radius: 0.5rem;
        -webkit-border-radius: 0.5rem;
        -moz-border-radius: 0.5rem;
        -ms-border-radius: 0.5rem;
        -o-border-radius: 0.5rem;
    }
    .login_modal .modal_body .content .options {
        margin-bottom: 1rem;
    }
    .login_modal .modal_body .content .options button {
        gap: 0;
    }
    .login_modal .modal_body .content .options button p {
        width: 110px;
    }

    .login_modal .modal_body .phone_content .phone_input .input_content,
    .login_modal .modal_body .email_content .email_input .input_content {
        height: 45px;
        padding: 8px;
        padding-left: 20px;
    }

    .login_modal .modal_body .phone_content .send,
    .login_modal .modal_body .email_content .send {
        height: 45px;
        padding: 8px;
    }

    .login_modal
        .modal_body
        .phone_content
        .phone_input
        .input_content
        .phone_code
        .MuiInputBase-root
        .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input,
    .login_modal
        .modal_body
        .phone_content
        .phone_input
        .input_content
        .phone_code
        .MuiSelect-root
        .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input {
        gap: 4px;
    }
}
