/*
// .site-footer
*/
@import "../variables";
@import "../functions";

.site-footer {
    margin-top: 16px;
    background: $footer-bg;
    border-top: $footer-border-top;
}

.site-footer__widgets {
    padding: 48px 0 46px;
}

.site-footer__bottom {
    height: 54px;
    border-top: $footer-copyright-border;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.site-footer__copyright {
    font-size: 14px;

    .name {
        color: var(--primary-bg);
        cursor: pointer;
    }
}

.site-footer__payments {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;

    img {
        width: 40px;
        min-width: 40px;
        max-width: 45px;
    }
}

.site-footer__widget {
    &+& {
        margin-top: 24px;
    }
}

@media (max-width: breakpoint(sm-end)) {
    .site-footer__widgets {
        padding-bottom: 48px;
    }

    .site-footer__bottom {
        padding: 20px 0 24px;
        flex-wrap: wrap;
        height: auto;
    }

    .site-footer__payments {
        order: -1;
        justify-content: flex-end;
    }

    .site-footer__copyright+.site-footer__payments {
        margin-bottom: 24px;
    }

    .site-footer__copyright,
    .site-footer__payments {
        width: 100%;
        text-align: center;
    }
}
