/* ----------------------------------------

Table of Contents:
- Common
  - animations
  - base
  - .document
  - .fake-svg-icon
  - .page-header
  - .site
- Components
  - .account-nav
  - .alert
  - .badge
  - .block-header
  - .breadcrumb
  - .btn
  - .card
  - .card-table
  - .filters-button
  - forms
  - .input-check
  - .input-check-color
  - .input-number
  - .input-radio
  - .input-radio-color
  - .input-radio-label
  - .input-range
  - .layout-switcher
  - .modal
  - .nouislider
  - .pagination
  - .post-card
  - .product-card
  - .product-image
  - .rating
  - .share-links
  - .slick-prevent-click
  - .social-links
  - .tags
  - .tooltip
  - .typography
- Filters
  - .filter
  - .filter-categories
  - .filter-color
  - .filter-list
  - .filter-price
- Header
  - .account-menu
  - .departments
  - .dropcart
  - .indicator
  - .megamenu
  - .menu
  - .mobile-header
  - .mobile-links
  - .mobilemenu
  - .nav-links
  - .nav-panel
  - .search
  - .search--location--header
  - .search--location--indicator
  - .search--location--mobile-header
  - .site-header
  - .suggestions
  - .topbar
  - .topbar-dropdown
  - .topbar-link
- Footer
  - .footer-contacts
  - .footer-links
  - .footer-newsletter
  - .site-footer
  - .totop
- Pages
  - .about-us
  - .checkout
  - .contact-us
  - .faq
  - .not-found
- Shop
  - .address-card
  - .addresses-list
  - .cart
  - .cart-table
  - .category-card
  - .compare-table
  - .dashboard
  - .order-header
  - .order-list
  - .order-success
  - .product
  - .product-gallery
  - .product-tabs
  - .products-list
  - .products-view
  - .profile-card
  - .quickview
  - .review
  - .reviews-list
  - .reviews-view
  - .shop-layout
  - .spec
  - .view-options
  - .wishlist
- Blog
  - .comment
  - .comments-list
  - .post
  - .post-author
  - .post-header
  - .posts-list
  - .posts-view
  - .related-posts
- Blocks
  - .block
  - .block-banner
  - .block-brands
  - .block-categories
  - .block-empty
  - .block-features
  - .block-loader
  - .block-map
  - .block-posts
  - .block-product-columns
  - .block-products
  - .block-products-carousel
  - .block-sidebar
  - .block-slideshow
- Widgets
  - .widget
  - .widget-aboutus
  - .widget-categories
  - .widget-comments
  - .widget-filters
  - .widget-newsletter
  - .widget-posts
  - .widget-products
  - .widget-search
  - .widget-tags

---------------------------------------- */

@import "~bootstrap/dist/css/bootstrap.min.css";

/* ----------------------------------------
// Common
// ---------------------------------------- */
@import "common/animations";
@import "common/base";
@import "common/document";
@import "common/fake-svg-icon";
@import "common/page-header";
@import "common/site";

/* ----------------------------------------
// Components
// ---------------------------------------- */
@import "components/account-nav";
@import "components/alert";
@import "components/badge";
@import "components/block-header";
@import "components/breadcrumb";
@import "components/buttons";
@import "components/card";
@import "components/card-table";
@import "components/filters-button";
@import "components/forms";
@import "components/input-check";
@import "components/input-check-color";
@import "components/input-number";
@import "components/input-radio";
@import "components/input-radio-color";
@import "components/input-radio-label";
@import "components/input-range";
@import "components/layout-switcher";
@import "components/modal";
@import "components/nouislider";
@import "components/pagination";
@import "components/post-card";
@import "components/product-card";
@import "components/product-image";
@import "components/rating";
@import "components/share-links";
@import "components/slick-prevent-click";
@import "components/social-links";
@import "components/tags";
@import "components/tooltip";
@import "components/typography";
@import "components/_login-modal";

/* ----------------------------------------
// Filters
// ---------------------------------------- */
@import "filters/filter";
@import "filters/filter-categories";
@import "filters/filter-color";
@import "filters/filter-list";
@import "filters/filter-price";

/* ----------------------------------------
// Header
// ---------------------------------------- */
@import "header/account-menu";
@import "header/departments";
@import "header/dropcart";
@import "header/indicator";
@import "header/megamenu";
@import "header/menu";
@import "header/mobile-header";
@import "header/mobile-links";
@import "header/mobilemenu";
@import "header/nav-links";
@import "header/nav-panel";
@import "header/search";
@import "header/search--location--header";
@import "header/search--location--indicator";
@import "header/search--location--mobile-header";
@import "header/site-header";
@import "header/suggestions";
@import "header/topbar";
@import "header/topbar-dropdown";
@import "header/topbar-link";

/* ----------------------------------------
// Footer
// ---------------------------------------- */
@import "footer/footer-contacts";
@import "footer/footer-links";
@import "footer/footer-newsletter";
@import "footer/site-footer";
@import "footer/totop";
@import "footer/whatsapp";

/* ----------------------------------------
// Pages
// ---------------------------------------- */
@import "pages/about-us";
@import "pages/checkout";
@import "pages/contact-us";
@import "pages/faq";
@import "pages/not-found";

/* ----------------------------------------
// Shop
// ---------------------------------------- */
@import "shop/address-card";
@import "shop/addresses-list";
@import "shop/cart";
@import "shop/cart-table";
@import "shop/category-card";
@import "shop/compare-table";
@import "shop/dashboard";
@import "shop/order-header";
@import "shop/order-list";
@import "shop/order-success";
@import "shop/product";
@import "shop/product-gallery";
@import "shop/product-tabs";
@import "shop/products-list";
@import "shop/products-view";
@import "shop/profile-card";
@import "shop/quickview";
@import "shop/review";
@import "shop/reviews-list";
@import "shop/reviews-view";
@import "shop/shop-layout";
@import "shop/spec";
@import "shop/view-options";
@import "shop/wishlist";

/* ----------------------------------------
// Blog
// ---------------------------------------- */
@import "blog/comment";
@import "blog/comments-list";
@import "blog/post";
@import "blog/post-author";
@import "blog/post-header";
@import "blog/posts-list";
@import "blog/posts-view";
@import "blog/related-posts";

/* ----------------------------------------
// Blocks
// ---------------------------------------- */
@import "blocks/block";
@import "blocks/block-banner";
@import "blocks/block-brands";
@import "blocks/block-categories";
@import "blocks/block-empty";
@import "blocks/block-features";
@import "blocks/block-loader";
@import "blocks/block-map";
@import "blocks/block-posts";
@import "blocks/block-product-columns";
@import "blocks/block-products";
@import "blocks/block-products-carousel";
@import "blocks/block-sidebar";
@import "blocks/block-slideshow";

/* ----------------------------------------
// Widgets
// ---------------------------------------- */
@import "widgets/widget";
@import "widgets/widget-aboutus";
@import "widgets/widget-categories";
@import "widgets/widget-comments";
@import "widgets/widget-filters";
@import "widgets/widget-newsletter";
@import "widgets/widget-posts";
@import "widgets/widget-products";
@import "widgets/widget-search";
@import "widgets/widget-tags";

@import "custom";

iframe {
    pointer-events: none;
}
